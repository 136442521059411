/* .introReal{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 235px;
}
#titredepage{
    font-family: cursive;
    font-weight: 500;
    text-decoration: underline;
    color: #09495e;
}
#pghrIntro{
    font-family: cursive;
} */
.introReal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 235px;
}

/* Title styling */
#titredepage {
    font-family: cursive;
    font-weight: 500;
    text-decoration: underline;
    color: #09495e;
    font-size: 2.5rem;
    margin-bottom: 20px;
}

/* Paragraph styling */
#pghrIntro {
    font-family: cursive;
    line-height: 1.6;
    font-size: 1.2rem;
    color: #333;
}

/* Responsive styles for tablets */
@media (max-width: 1024px) {
    .introReal {
        padding: 0px 50px;
    }
    #titredepage {
        font-size: 2rem;
    }
    #pghrIntro {
        font-size: 1.1rem;
    }
}

/* Responsive styles for mobile devices */
@media (max-width: 768px) {
    .introReal {
        padding: 0px 30px;
    }
    #titredepage {
        font-size: 1.8rem;
    }
    #pghrIntro {
        font-size: 1rem;
    }
}

/* Extra small devices */
@media (max-width: 480px) {
    .introReal {
        padding: 0px 10px;
    }
    #titredepage {
        font-size: 1.5rem;
        text-align: center;
    }
    #pghrIntro {
        font-size: 0.9rem;
        text-align: justify;
    }
}
