
/* #116986 */
.navbarHA{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-color: #d8dadc;
}
#navbarImage{
    width: 104px;
}
.contenuNav{
    display: flex;
    align-items: center;
    width: 500px;
    justify-content: space-between;
}
.contenuNavBarHA{
    font-family: cursive;
    font-weight: 500;
    text-decoration: none;
    color: #09495e;
}