
#logoAcceuil {
  width: 633px;
}

#pargrapheAcceuil {
  font-size: large;
  color: #09495e;
  padding: 0px 65px;
}

.PermierdivAcceuil {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

#nomdentreprise {
  color: #e07c16;
  font-style: italic;
  font-weight: 600;
}

.divAcceuil {
  display: flex;
  height: 633px;
  margin-bottom: 0;
  background-color: #ffffff;
  background-image: linear-gradient(90deg, #ffffff 0%, #d8dadc 100%);
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

#titreDuAcceuil {
  font-size: 2.5rem;
  font-weight: bold;
}

.roll-container {
  width: 441px;
  height: 100px;
  overflow: hidden;
  position: relative;
  background-color: #e07c16;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

/* Media queries for tablets (screen widths between 768px and 1024px) */
@media (max-width: 1024px) {
  #logoAcceuil {
    width: 400px;
  }

  #pargrapheAcceuil {
    font-size: medium;
    padding: 0px 40px;
  }

  .PermierdivAcceuil {
    flex-direction: column;
    align-items: center;
  }

  #titreDuAcceuil {
    font-size: 2rem;
  }

  .roll-container {
    width: 350px;
    height: 80px;
  }
}

/* Media queries for mobile devices (screen widths less than 768px) */
@media (max-width: 768px) {
  #logoAcceuil {
    width: 300px;
  }

  #pargrapheAcceuil {
    font-size: small;
    padding: 0px 20px;
  }

  .PermierdivAcceuil {
    flex-direction: column;
    align-items: center;
  }

  #titreDuAcceuil {
    font-size: 1.5rem;
  }

  .roll-container {
    width: 250px;
    height: 60px;
  }
}
