.realisation{
    padding-top: 60px;
    background-color: #ffffff;
    background-image: linear-gradient(0deg, #ffffff 0%, #d8dadc 100%);
}

/* Responsive styles for tablets */
@media (max-width: 1024px) {
    .realisation {
        padding-top: 40px;
        padding-left: 15px;
        padding-right: 15px;
    }
}

/* Responsive styles for mobile devices */
@media (max-width: 768px) {
    .realisation {
        padding-top: 30px;
        padding-left: 10px;
        padding-right: 10px;
    }
}

/* Extra small devices */
@media (max-width: 480px) {
    .realisation {
        padding-top: 20px;
        padding-left: 5px;
        padding-right: 5px;
    }
}