

#idCarouselOneRealistaion {
    min-height: 90.1vh;
    padding-bottom: 30px;
    border-bottom: 0.5px solid #09495e;
    margin-bottom: 10px;
}

/* Adjust carousel width for responsiveness */
.carousel-inner {
    position: relative;
    width: 100%; /* Use 100% width to cover the entire space */
    overflow: hidden;
}

/* Adjusting each photo block */
#blocPhotoThree {
   margin-left: 407px;
    width: 49%;
    height: 99vh;
}

/* Adjusting the carousel control buttons */
.carousel-control-prev-icon, .carousel-control-next-icon {
    background-color: black;
}

/* Title styling */
#titredeRealisationPhoto {
    color: #000000;
    font-family: cursive;
    font-weight: 900;
    text-decoration: underline;
    font-size: 1.5rem;
}

/* Paragraph styling */
#pghrPhoto {
    font-family: cursive;
    font-size: 1rem;
    line-height: 1.5;
}

/* Responsive styles for tablets */
@media (max-width: 1024px) {
    #blocPhotoThree {
        margin: 0 auto; /* Center the carousel items */
        width: 146%; /* Full width on smaller devices */
       ; /* Adjust height automatically */ /* Decrease width slightly for tablet screens */
    }
    #titredeRealisationPhoto {
        font-size: 1.3rem; /* Adjust title font size */
        margin-left: -181px;
    }
    #pghrPhoto {
        padding: 0px 123px;
        font-size: 0.85rem;
        text-align: center;
        margin-left: -175px;
    }
}

/* Responsive styles for mobile devices */
@media (max-width: 768px) {
    #blocPhotoThree {
        margin: 0 auto; /* Center the carousel items */
        width: 146%; /* Full width on smaller devices */
    height: auto; /* Adjust height automatically */ /* Increase width for better fit on mobile */
    }
    #titredeRealisationPhoto {
        font-size: 1.2rem;
        margin-left: -181px;
    }
    #pghrPhoto {
        padding: 0px 123px;
        font-size: 0.85rem;
        text-align: center;
        margin-left: -175px;
    }
}

/* Extra small devices */
@media (max-width: 480px) {
    #blocPhotoThree {
        margin: 0 auto; /* Center the carousel items */
        width: 146%; /* Full width on smaller devices */
        height: auto; /* Adjust height automatically *//* Full width for small devices */
    }
    #titredeRealisationPhoto {
        font-size: 1rem;
        text-align: center;
        margin-left: -181px;
    }
    #pghrPhoto {
        padding: 0px 123px;
        font-size: 0.85rem;
        text-align: center;
        margin-left: -175px;
    }
}
