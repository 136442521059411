#PgContact {
  width: 100%;
  min-height: 90.1vh;
  background-color: #d8dadc;
  background-image: linear-gradient(180deg, #d8dadc 0%, #e07c16 100%);
  background-blend-mode: color;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 20px;
}

.headreContact {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 90px;
}

#titreDePage {
  cursor: default;
  font-family: cursive;
  font-size: 30px;
  color: black;
}

#presDePage {
  font-family: cursive;
}

.BodyContact {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.CrdContact {
  border: 0.5px solid #aaa4a4;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 333px;
  height: 228px;
  justify-content: center;
  margin: 17px 15px;
  background: #ffffff91;
  border-radius: 3%;
  transition: all 0.3s ease-in-out;
}

.icnContc {
  font-size: 29px;
}

.titreCard {
  margin: 17px 0 0 0;
}

.pghrCard {
  text-align: center;
}

#numContact {
  font-size: large;
  font-weight: 500;
}

.footerContact {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

#phraseFooter {
  font-size: 19px;
  font-weight: 500;
  text-align: center;
  padding: 0 15px;
}

.iconFooter {
  /* width: 25%; */
  display: flex;
  justify-content: space-between;
}

#IconFacebook {
  font-size: 35px;
  color: #4267b3;
}
#IconInsta {
  font-size: 35px;
  color: #e1306c;
}

/* Tablettes et petites résolutions */
@media screen and (max-width: 1200px) {
  .BodyContact {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .CrdContact {
    width: 80%;
    height: auto;
    margin: 20px 0;
    padding: 20px;
  }

  #titreDePage {
    font-size: 28px;
  }

  #presDePage {
    font-size: 18px;
    text-align: center;
  }
}

/* Smartphones (moins de 768px) */
@media screen and (max-width: 768px) {
  .BodyContact {
    flex-direction: column;
    width: 100%;
  }

  .CrdContact {
    width: 90%;
    padding: 15px;
    margin: 10px 0;
  }

  .icnContc {
    font-size: 24px;
  }

  #titreDePage {
    font-size: 24px;
  }

  #presDePage {
    font-size: 16px;
    text-align: center;
  }

  #numContact {
    font-size: medium;
  }

  #phraseFooter {
    font-size: 16px;
  }

  /* .iconFooter {
        width: 50%;
    } */

  #IconFacebook,
  #IconInsta {
    font-size: 30px;
  }
}

/* Très petits écrans (moins de 360px) */
@media screen and (max-width: 360px) {
  .CrdContact {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
  }

  .icnContc {
    font-size: 20px;
  }

  #titreDePage {
    font-size: 20px;
  }

  #presDePage {
    font-size: 14px;
  }

  #numContact {
    font-size: small;
  }

  #phraseFooter {
    font-size: 14px;
    text-align: center;
  }

  /* .iconFooter {
        width: 60%;
    } */

  #IconFacebook,
  #IconInsta {
    font-size: 28px;
  }
}
