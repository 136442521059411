/* #videoPresentationAcceuil{
width: 380px;
}
.AcceuilPageTwo{
    padding: 22px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    background-image: linear-gradient(90deg, #ffffff 0%, #d8dadc 100%); background-image: url("/public/LogoHANav.png") ; 
    background-size: 10%;
    background-repeat: repeat-y;
    animation: moveBackground 10s linear infinite;
}
@keyframes moveBackground {
    0% {
        background-position: 23% 4%;
    }
    100% {
        background-position: 10% 70%;
    }
} */

#videoPresentationAcceuil {
    width: 380px; /* Prend toute la largeur disponible */
    max-width: 1200px; /* Limite la taille maximale sur les grands écrans */
    height: auto; /* Garde le ratio de la vidéo */
  }
  
  .AcceuilPageTwo {
    /* padding: 22px 0px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    background-image: linear-gradient(90deg, #ffffff 0%, #d8dadc 100%);
    background-image: url("/public/LogoHANav.png");
    background-size: 10%;
    background-repeat: repeat-y;
    animation: moveBackground 10s linear infinite;
  }
  
  @keyframes moveBackground {
    0% {
      background-position: 23% 4%;
    }
    100% {
      background-position: 10% 70%;
    }
  }
  
  /* Media queries pour tablettes (écrans de moins de 1024px) */
  @media (max-width: 1024px) {
    #videoPresentationAcceuil {
      width: 100%; /* La vidéo prend toute la largeur */
      max-width: 900px; /* Limiter la taille pour les tablettes */
      height: auto; /* Garder le ratio de la vidéo */
    }
    .AcceuilPageTwo {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: #ffffff;
      background-image: none; /* Supprimer l'image de fond pour tablettes */
    }
  }
  
  /* Media queries pour appareils mobiles (écrans de moins de 768px) */
  @media (max-width: 768px) {
    #videoPresentationAcceuil {
      width: 100%; /* La vidéo prend 100% de la largeur de l'écran */
      height: auto; /* Garder le ratio */
    }
    .AcceuilPageTwo {
      display: flex;
      flex-direction: column; /* Empiler les éléments en colonne */
      align-items: center;
      justify-content: center;
      background-color: #ffffff;
      background-image: none; /* Supprimer l'image de fond pour les mobiles */
    }
  }
  